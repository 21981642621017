.swiper {
  width: 80vw;
  height: 50vw;
}

.swiper-slide-next {
  border-radius: 5px;
  transform: translate3d(calc(-80vw + 7.25%), 0px, -100px) rotateZ(4deg) scale(1) !important;
}

.swiper-slide-prev {
  border-radius: 5px;
  transform: translate3d(calc(0px - 7.25%), 0px, -100px) rotateZ(-4deg) scale(1) !important;
}